/* eslint-disable indent */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from '@mui/material';

import {
  CustomButton,
  Typography10px400,
  Typography12px400,
} from '../../../../styles/Global.styles';
import { useForm, FieldValues } from 'react-hook-form';
import { EvaluationNotesPayload } from '../../reducers/putEvaluationNotes';
import { EvaluationNotesResponse } from '../../reducers/getEvaluationNotes';
import { useAppSelector } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import usePutEvaluationNotes from '../../hooks/usePutEvaluationNotes';
import useGetEvaluationNotes from '../../hooks/useGetEvaluationNotes';
import EvaluationCloudinaryUploadComponent from '../../../Cloudinary/Components/EvaluationCloudinaryUploadWidget';
import { useBranding } from '../../../Branding/reducers/getBranding';

// V2 HERE
// -evaluationNotes
// -updateEvaluationNotes

const placeholderText = {
  color: '#B5B5C3',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

const innerText = {
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  paddingTop: '5px',
};

const EvaluationAnalysisNotes = (prop: {
  id: string;
}) => {
  const branding = useBranding();
  const usePutEvaluationNotesReducer = usePutEvaluationNotes();
  const useGetEvaluationNotesReducer = useGetEvaluationNotes();

  const resultAnalysisForm = useForm<FieldValues & EvaluationNotesPayload>({
    defaultValues: {
      evaluation_notes: ''
    },
  });

  const { register, control, setValue, handleSubmit } = resultAnalysisForm;

  const evaluationNotesResponse: EvaluationNotesResponse | null =
    useAppSelector((state) => {
      return state.allEvaluationReducers.getEvaluationNotesReducer.evaluationNotes;
    });

  const displayCloudinaryUploadWidget = (
    (branding?.company_name == "test" || branding?.company_name == "Impact Washington") &&
    evaluationNotesResponse?.evaluation_category_name == "Food & Beverage");

  React.useEffect(() => {
    useGetEvaluationNotesReducer.evaluationNotes(
      prop.id
    );
  }, [prop.id]);

  React.useEffect(() => {
    if (evaluationNotesResponse) {
      setValue('evaluation_notes', evaluationNotesResponse.evaluation_notes);
    }
  }, [evaluationNotesResponse, setValue]);

  const onSubmit = (data: EvaluationNotesPayload) => {
    usePutEvaluationNotesReducer.updateEvaluationNotes({
      params: {
        evaluation_id: prop.id
      },
      payload: data,
    });
  };
  const navigate = useNavigate();

  return !evaluationNotesResponse ||
    useGetEvaluationNotesReducer.loading ||
    useGetEvaluationNotesReducer.error ? (
    <CircularProgress sx={{ margin: '20vh 48%' }} />
  ) :
    // evaluationAnalysisResponse.average_evaluation_score === 0 ? (
    //   <Grid
    //     container
    //     sx={{
    //       backgroundColor: 'var(--primary-light, #F7F9FB)',
    //       padding: '20px',
    //       borderRadius: '12px',
    //       display: 'flex',
    //       justifyContent: 'space-between',
    //     }}
    //   >
    //     <Typography14px600>
    //       {'No question is in this question category.'}
    //     </Typography14px600>
    //   </Grid>
    // ) : 
    (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          sx={{
            paddingTop: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid item sm={12}>
            {evaluationNotesResponse.evaluation_status !== "Results Review" && (
              <Typography
                sx={{
                  color: 'red',
                  marginX: '10px',
                  marginY: '0px',
                  fontSize: '12px'
                }}
              >
                Evaluations must be in the &apos;Results Review&apos; to save any changes made in the Notes.
              </Typography>
            )}

            <Box
              sx={{
                margin: '20px 10px',
                marginTop: '10px'
              }}>
              <Typography12px400
                sx={{
                  color: '#181C32',
                  paddingBottom: '5px',
                }}
              >
                Evaluation Notes
              </Typography12px400>

              <Typography10px400
                sx={{
                  color: '#B5B5C3',
                }}
              >
                Add any additional notes to be included in the report.
              </Typography10px400>

              <TextField
                placeholder='Add any additional notes to be included in the report.'
                id="outlined-basic"
                sx={{
                  width: '90%',
                  marginTop: '10px',
                }}
                multiline={true}
                rows={20}
                InputLabelProps={{
                  style: placeholderText,
                }}
                InputProps={{
                  style: innerText,
                }}
                {...register('evaluation_notes')}
              />
            </Box>
          </Grid>
          <Grid
            item
            sm={12}
            sx={{
              margin: '30px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              type="submit"
              sx={{
                color: '#fff',
                minWidth: '100px',
              }}
            >
              Save
            </CustomButton>
          </Grid>

          <Grid
            item
            sm={12}
            sx={{
              margin: '30px',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {displayCloudinaryUploadWidget && <EvaluationCloudinaryUploadComponent id={prop.id} />}
          </Grid>
        </Grid>
      </form>
    );
};

export default EvaluationAnalysisNotes;
