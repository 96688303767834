/* eslint-disable indent */
import React from 'react';
import {
    Box,
    Typography,
    Grid,
    TextField,
    Button
} from '@mui/material';
import { LinkSharp } from '@mui/icons-material';
import { EvaluationDetail } from '../../reducers/getEvaluationDetail';
import { DownloadIcon } from '../../../../assets/svg-components/SvgIcons';
import { Tenant } from '../../../../config/axios';
import { useBranding } from '../../../Branding/reducers/getBranding';

const EvaluationReport = ({ info }: { info: EvaluationDetail }) => {
    //const location = useLocation();
    //const generateReportReducer = useGetEvaluationReport();
    const branding = useBranding();
    const isImpactWashington = branding?.company_name.toLowerCase().includes("washington");

    const backendReport =
        isImpactWashington // Temporary while we get Impact Washington in place.
            ? `api/v1/evaluate/report/?evaluation_id=${info.id}`
            : `api/v1/evaluate/generate-report/?evaluation_id=${info.id}`;
    const reportGenerationUrl: string = (Tenant.domain as string) + backendReport;

    const copyReportLinkToClipboard = (download: boolean) => {
        const tempInput = document.createElement('input');
        tempInput.id = 'evaluation-report-link';
        tempInput.value = reportGenerationUrl + (download ? "&download=true" : '');

        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    };

    return (
        <Box>
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
            >
                Share Report
            </Typography>
            <Box>
                <Typography
                    sx={{
                        marginTop: '14px',
                        fontSize: '14px',
                        fontWeight: 600,
                    }}
                    variant="h5"
                    component="h2"
                    gutterBottom
                >
                    Online Report
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: '#B5B5C3',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        marginBottom: '14px',
                    }}
                >
                    Anyone with the link will be able to view the report
                </Typography>
                <Grid container gap={1} display={'flex'} alignItems={'center'}>
                    <Grid item sm={8}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={reportGenerationUrl}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Button
                            size="small"
                            sx={{
                                backgroundColor: '#000',
                                textTransform: 'none',
                                maxHeight: '32px',
                                color: '#fff',
                                padding: '10px 20px',
                                whiteSpace: 'nowrap',
                                '&:hover': {
                                    backgroundColor: '#000',
                                    color: '#fff',
                                },
                            }}
                            endIcon={<LinkSharp />}
                            onClick={() => { copyReportLinkToClipboard(false) }}
                        >
                            Copy Link
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Typography
                    sx={{
                        marginTop: '14px',
                        fontSize: '14px',
                        fontWeight: 600,
                    }}
                    variant="h5"
                    component="h2"
                    gutterBottom
                >
                    Print Report
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: '#B5B5C3',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        marginBottom: '14px',
                    }}
                >
                    Anyone with the link will be able to download the report
                </Typography>
                <Grid container gap={1} display={'flex'} alignItems={'center'}>
                    <Grid item sm={8}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={`${reportGenerationUrl}&download=true`}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <Button
                            size="small"
                            sx={{
                                backgroundColor: '#000',
                                textTransform: 'none',
                                maxHeight: '32px',
                                color: '#fff',
                                padding: '10px 20px',
                                whiteSpace: 'nowrap',
                                '&:hover': {
                                    backgroundColor: '#000',
                                    color: '#fff',
                                },
                            }}
                            endIcon={<LinkSharp />}
                            onClick={() => { copyReportLinkToClipboard(true) }}
                        >
                            Copy Link
                        </Button>
                    </Grid>
                    <Button
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            textTransform: 'none',
                            color: '#000',
                            marginLeft: '0',
                            whiteSpace: 'nowrap',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: '#000',
                            },
                        }}
                        onClick={() => {
                            const url = `${reportGenerationUrl}&download=true`;
                            window.open(url, '_blank');
                        }}
                    >
                        <DownloadIcon />
                    </Button>
                </Grid>
            </Box>
        </Box>
    );
};

export default EvaluationReport;