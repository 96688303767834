/* eslint-disable indent */
import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Tooltip,
    useTheme
} from '@mui/material';
import { EvaluationDetail } from '../../reducers/getEvaluationDetail';
import usePostEvaluationLink from '../../hooks/usePostEvaluationLink';
import { EvaluationStatus } from '../../type';

const DirectRecipientCard = ({ info }: { info: EvaluationDetail }) => {
    const { postSurveyLink } = usePostEvaluationLink();
    const theme = useTheme();
    return (
        <Box
            sx={{
                marginTop: '40px',
            }}
        >
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                }}
                variant="h5"
                component="h2"
                gutterBottom
            >
                Direct Recipient
            </Typography>

            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 400,
                            }}
                            variant="h6"
                            component="h6"
                            gutterBottom
                        >
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                            }}
                        >
                            {info?.recipients_list.map((recipient, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Grid item xs={12} sm={8}>
                                            <Tooltip title={recipient} placement="top-start">
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        minWidth: '205px',
                                                    }}
                                                    variant="body1"
                                                >
                                                    {recipient || ''}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {info.evaluation_status === EvaluationStatus.IN_PROGRESS && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        textTransform: 'none',
                                                        borderRadius: '6px',
                                                        padding: '4px 12px',
                                                        fontSize: '14px',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            backgroundColor: theme.palette.primary.main,
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        postSurveyLink({
                                                            email_list: [recipient],
                                                            evaluation_id: info.id,
                                                        });
                                                    }}
                                                    variant="contained"
                                                >
                                                    Resend
                                                </Button>
                                            )}
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default DirectRecipientCard;