import { useBranding } from '../modules/Branding/reducers/getBranding';
import { Insights, Handshake, LocalShipping } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../redux/store.type';
import { CustomAccountBranding } from '../modules/Branding/type';

export interface Route {
  name: string;
  url: string;
  icon?: string | React.ComponentType;
  submenus?: SubMenu[];
}

interface SubMenu {
  name: string;
  url: string;
}

export const useRoutes = (): Route[] => {
  const branding = useBranding();
  const scoinCustomer = branding?.active_feature_flags?.is_scoin_customer_flag === true;
  const dashboardAccess = branding?.active_feature_flags?.is_dashboard_customer_flag === true;
  const loggedInUserType = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.authReducers.user.currentUser?.user_type;
  });

  function getEvaluationTranslation(branding: CustomAccountBranding | null, defaultText = 'Evaluations'): string {
    if (!branding) {
      return defaultText;
    }

    return branding.evaluation_property_name_translation as string || defaultText;
  }

  const evaluationNameTranslation = getEvaluationTranslation(branding);

  let Routes: Route[] = [
    {
      name: 'Accounts',
      url: 'account-list',
      icon: require('../assets/accounts.png'),
      submenus: [
        { name: 'Accounts List', url: 'account-list' },
        { name: 'Account Overview', url: 'account-detail-view' },
        { name: 'Create account', url: 'create-account' },
      ],
    },
    {
      name: `${evaluationNameTranslation}`,
      url: 'evaluation-assessment-list',
      icon: require('../assets/evaluation.png'),
      submenus: [
        { name: `${evaluationNameTranslation} List`, url: 'evaluation-assessment-list' },
        { name: `${evaluationNameTranslation} Overview`, url: `evaluation-assessment-detail` },
        {
          name: `${evaluationNameTranslation} By Question`,
          url: 'evaluation-assessment-analysis-score',
        },
        { name: `Create ${evaluationNameTranslation}`, url: 'create-evaluation-assessment' },
      ],
    },
    // {
    //   name: 'Settings',
    //   url: 'settings',
    //   icon: require('../assets/settings.png'),
    // },
  ];

  if (scoinCustomer) {
    Routes = [
      ...Routes,
      {
        name: 'Ecosystem',
        url: 'scoin',
        icon: LocalShipping,
      }
    ];
  }

  //Sets Dashboard access
  if (dashboardAccess && (!loggedInUserType?.includes("client"))) {
    Routes = [
      {
        name: 'Dashboard',
        url: 'dashboard',
        icon: require('../assets/dashboard.png')
      },
      ...Routes
    ];
  } else if (dashboardAccess) {
    Routes = [
      {
        name: 'Dashboard',
        url: 'client-dashboard',
        icon: require('../assets/dashboard.png')
      },
    ];

  }

  return Routes;
};
